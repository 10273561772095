var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card-body", staticStyle: { direction: "rtl" } }, [
      _c(
        "form",
        {
          attrs: { autocomplete: "off" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.makeOrder.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "table",
            { staticClass: "table", staticStyle: { "text-align": "right" } },
            [
              _c("tbody", [
                _vm._m(1),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("\n              عنوان التسليم\n            "),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.deliveryDetails
                          ? _vm.deliveryDetails.deliveryAddress.address
                          : ""
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "table", staticStyle: { "text-align": "right" } },
            [
              _c("tbody", [
                _vm._m(4),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("\n              المجموع الفرعي\n            "),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.$currency + " " + _vm.subTotal)),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("\n              رسوم التوصيل\n            "),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n              + " +
                        _vm._s(_vm.$currency + " " + _vm.deliveryCharge) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("\n              الخصم\n            ")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v("- " + _vm._s(_vm.$currency + " " + _vm.discount)),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("\n              المجموع الإجمالي\n            "),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.$currency + " " + _vm.grandTotal)),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-block mt-2",
              staticStyle: { "background-color": "var(--primary-color)" },
              attrs: { type: "submit" },
            },
            [
              _vm.loading
                ? _c("span", {
                    staticClass: "spinner-border spinner-border-sm",
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.loading
                ? _c("span", [_vm._v("\n          اطلب الأن\n        ")])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "text-center" }, [
        _vm._v("\n      معاينة الطلب\n    "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "2" } }, [
        _c("b", [_vm._v("\n                تفاصيل التسليم\n              ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("\n              نوع التوصيل\n            ")]),
      _vm._v(" "),
      _c("td", [_vm._v("\n              Express\n            ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("\n              طريقة الدفع او السداد\n            ")]),
      _vm._v(" "),
      _c("td", [_vm._v("\n              Cash On Delivery\n            ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "2" } }, [
        _c("b", [_vm._v("\n                ملخص الطلب\n              ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }