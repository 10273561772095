var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("h4", { staticClass: "header-title" }, [
            _vm._v(_vm._s(_vm.$lang["My Assigned Orders"])),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _vm.loading
            ? _c("div", { staticClass: "text-center py-5" }, [
                _c("span", { staticClass: "spinner-border" }),
              ])
            : _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c("th", [_vm._v(_vm._s(_vm.$lang["ID"]))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$lang["Customer"]))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$lang["Amount"]))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$lang["Payment"]))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$lang["Method"]))]),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$lang["Action"])),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      !_vm.loading && _vm.orders.length == 0
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center py-4",
                                attrs: { colspan: "6" },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$lang["No orders found"]) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.orders, function (order) {
                        return _c("tr", { key: order.id }, [
                          _c("td", [
                            _vm._v("#" + _vm._s(order.order_id)),
                            _c("br"),
                            _vm._v(_vm._s(order.created_at)),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(order.name)),
                            _c("br"),
                            _vm._v(_vm._s(order.phone)),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.$currency + " " + order.grand_total)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.paymentStatus(order)),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  order.payment_method
                                    .replaceAll("_", " ")
                                    .toUpperCase()
                                ) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-center" }, [
                            _c("div", { staticClass: "dropdown" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-light text-dark btn-sm dropdown-toggle",
                                  attrs: {
                                    type: "button",
                                    id: "dropdownMenuButton",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$lang["Action"]) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: {
                                    "aria-labelledby": "dropdownMenuButton",
                                  },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        to: {
                                          name: "view_order",
                                          params: { id: order.order_id },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.$lang["Order details"]) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showModal(order)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$lang["Customer details"]
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.markAsDelivered(
                                            order.order_id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$lang["Mark as delivered"]
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
          _vm._v(" "),
          _c("nav", [
            _c(
              "ul",
              { staticClass: "pagination pagination-bordered" },
              [
                _c(
                  "li",
                  {
                    class: [
                      _vm.current_page == 1 ? "disabled" : "",
                      "page-item",
                    ],
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "page-link",
                        on: {
                          click: function ($event) {
                            return _vm.paginate(_vm.current_page - 1)
                          },
                        },
                      },
                      [_c("i", { staticClass: "icofont-rounded-left" })]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.total_page, function (page) {
                  return _c(
                    "li",
                    {
                      key: page,
                      class: [
                        _vm.current_page == page ? "active" : "",
                        "page-item",
                      ],
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "page-link",
                          on: {
                            click: function ($event) {
                              return _vm.paginate(page)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " + _vm._s(page) + "\n            "
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: [
                      _vm.current_page == _vm.total_page ? "disabled" : "",
                      "page-item",
                    ],
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "page-link",
                        on: {
                          click: function ($event) {
                            return _vm.paginate(_vm.current_page + 1)
                          },
                        },
                      },
                      [_c("i", { staticClass: "icofont-rounded-right" })]
                    ),
                  ]
                ),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "modal",
        { attrs: { name: "customer-details", height: "auto", adaptive: true } },
        [
          _c("div", { attrs: { id: "productDetailsModal" } }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h4", { staticClass: "modal-title text-white pl-2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$lang["Customer Details"]) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: { click: _vm.hideModal },
                  },
                  [_c("i", { staticClass: "icofont-close-line-squared-alt" })]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body p-4" }, [
                _c("table", { staticClass: "table" }, [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$lang["Name"]))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(this.order.name))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$lang["Email"]))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(this.order.email))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$lang["Phone"]))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(this.order.phone))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$lang["Address"]))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(this.order.shipping_address))]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }