var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "card card-signin my-5" }, [
          _c("div", { staticClass: "card-header text-center" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$lang["Verify your email address"]) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body text-center" }, [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$lang[
                      "Before proceeding, please check your email for a verification link."
                    ]
                  ) +
                  "\n            "
              ),
              _c("br"),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$lang["If you did not receive the email"]) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _vm.loading
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "subbuttonmit" },
                  },
                  [
                    _c("span", {
                      staticClass: "spinner-border spinner-border-sm",
                    }),
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.requestAnother },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$lang["click here to request another"]) +
                        "\n          "
                    ),
                  ]
                ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }