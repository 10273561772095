var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column align-items-center justify-content-center",
      attrs: { id: "notfound" },
    },
    [
      _c("img", { attrs: { src: "/theme/img/page-not-found.jpg" } }),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "nav-link",
          attrs: { to: { name: "home" }, exact: "" },
          nativeOn: {
            click: function ($event) {
              return _vm.triggerNav.apply(null, arguments)
            },
          },
        },
        [
          _c("i", { staticClass: "icofont-home" }),
          _vm._v(" " + _vm._s(_vm.$lang["Go to Home"]) + "\n  "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }