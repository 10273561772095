var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12 mx-auto my-5" }, [
            _c("div", { attrs: { id: "page" } }, [
              _c("h3", [_vm._v(_vm._s(_vm.page.title))]),
              _vm._v(" "),
              _c("p", { domProps: { innerHTML: _vm._s(_vm.page.body) } }),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }