var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("section", { staticClass: "popular-items section" }, [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("h3", { staticClass: "section-heading" }, [
            _vm._v("\n        عروض خاصة\n      "),
          ]),
          _vm._v(" "),
          _vm.loading && _vm.load_more ? _c("ProductSkelton") : _vm._e(),
          _vm._v(" "),
          _c("Products", {
            attrs: {
              products: _vm.products,
              productType: "offer",
              loading: _vm.loading,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "banner" }, [
      _c(
        "div",
        {
          staticClass: "banner-content d-flex align-items-center",
          staticStyle: { background: "url('/theme/img/main-banner.jpg')" },
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row text-center" }, [
              _c("div", { staticClass: "col-lg-8 offset-lg-2" }, [
                _c("h2", [_vm._v("\n              عروض خاصة\n            ")]),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }