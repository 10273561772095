var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isSettingsLoading
    ? _c(
        "div",
        [
          _c("header", [
            _c("div", { staticClass: "main-menu-area" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "search-area flex-grow-1" }, [
                    _c("div", { staticClass: "home-search" }, [
                      _c("input", {
                        staticStyle: {
                          direction: "rtl",
                          background: "#e5e5e5",
                          "border-radius": "20px",
                        },
                        attrs: {
                          type: "text",
                          name: "search",
                          id: "search",
                          autocomplete: "off",
                          placeholder: "أبحث هنا",
                        },
                        domProps: { value: _vm.keyword },
                        on: {
                          keyup: function ($event) {
                            return _vm.searchProduct($event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm._m(1),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(2),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("ShoppingCart"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mobile-nav", attrs: { id: "content-area" } },
            [
              _c("Sidebar"),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { id: "content" } },
                [
                  _c("event-hub"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "main-content" },
                    [_c("router-view")],
                    1
                  ),
                  _vm._v(" "),
                  _c("Footer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "left-header",
        staticStyle: {
          "align-items": "center",
          display: "flex",
          "justify-content": "flex-start",
        },
      },
      [
        _c("span", { attrs: { id: "sidebarToggle" } }, [
          _c("div", { staticClass: "lines" }, [
            _c("span"),
            _vm._v(" "),
            _c("span"),
            _vm._v(" "),
            _c("span"),
            _vm._v(" "),
            _c("span"),
          ]),
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "" } }, [
          _c("img", {
            staticClass: "logo d-none d-md-inline-block",
            attrs: { src: "/theme/img/website-logo-1.jpeg" },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticStyle: { position: "absolute", right: "10px", top: "10px" } },
      [_c("i", { staticClass: "icofont-search" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right-header ml-lg-2" }, [
      _c(
        "div",
        { staticClass: "menu-extra float-right d-none d-lg-inline-block" },
        [_c("ul")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }