var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "banner" }, [
      _c(
        "div",
        {
          staticClass: "banner-content d-flex align-items-center",
          style: { background: `url(${_vm.bannerImage})` },
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row text-center" }, [
              _c("div", { staticClass: "col-lg-8 offset-lg-2" }, [
                _c("h2", [_vm._v(_vm._s(_vm.settings.main_heading))]),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "category section" }, [
      _c("div", { staticClass: "container" }, [
        _c("h3", { staticClass: "section-heading" }, [
          _vm._v("\n        فئات المنتجات\n      "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm._l(_vm.allCategories, function (category) {
              return [
                category.parent_id == null
                  ? _c(
                      "div",
                      { key: category.id, staticClass: "col-lg-3 col-md-6" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "categoryProducts",
                                params: { slug: category.slug },
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "category-item" }, [
                              _c("img", { attrs: { src: category.image } }),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(category.name))]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.products.length > 0
      ? _c("section", { staticClass: "popular-items section" }, [
          _c("div", { staticClass: "container" }, [
            _c("h3", { staticClass: "section-heading" }, [
              _vm._v(_vm._s(_vm.$lang["Special Offers"])),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "slider" } },
              [
                _c("Products", {
                  attrs: {
                    products: _vm.products,
                    productType: "offer",
                    offerStyle: "slider",
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }