var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-6 mx-auto my-5" },
        [
          _vm.step == 1 ? _c("Address") : _vm._e(),
          _vm._v(" "),
          _vm.step == 2 ? _c("Confirm") : _vm._e(),
          _vm._v(" "),
          _vm.step == 3 ? _c("Complete") : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }