var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "row payment-form" }, [
    _vm.stripeValidationError != ""
      ? _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "alert alert-danger" }, [
            _c("span", [_vm._v(" " + _vm._s(_vm.stripeValidationError))]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 card-element" }, [
      _c("label", [_vm._v(_vm._s(_vm.$lang["Card Number"]))]),
      _vm._v(" "),
      _c("div", {
        staticClass: "input-value",
        attrs: { id: "card-number-element" },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-6 card-element" }, [
      _c("label", [_vm._v(_vm._s(_vm.$lang["Expiry Date"]))]),
      _vm._v(" "),
      _c("div", { attrs: { id: "card-expiry-element" } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-6 card-element" }, [
      _c("label", [_vm._v(_vm._s(_vm.$lang["CVC"]))]),
      _vm._v(" "),
      _c("div", { attrs: { id: "card-cvc-element" } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 mt-4" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-block",
          on: { click: _vm.placeOrderButtonPressed },
        },
        [
          _vm.loading
            ? _c("span", { staticClass: "spinner-border spinner-border-sm" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.loading
            ? _c("span", [_vm._v(_vm._s(_vm.$lang["Pay Now"]))])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }