var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("div", { attrs: { id: "footer" } }, [
      _c("div", { staticClass: "footer-top" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-6" }, [
              _c("div", { staticClass: "footer-item" }, [
                _c("h4", [
                  _vm._v("\n                عن الموقع\n              "),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.settings.about_us))]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-3" }, [
              _c("div", { staticClass: "footer-item" }, [
                _c("h4", [
                  _vm._v("\n                روابط مهمة\n              "),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.pages, function (p) {
                    return _c(
                      "li",
                      { key: p.id },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "page", params: { slug: p.slug } },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(p.title) +
                                "\n                  "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-3" }, [
              _c("div", { staticClass: "footer-item" }, [
                _c("h4", [
                  _vm._v("\n                الفئات المشهورة\n              "),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.popularCategory, function (pc) {
                    return _c(
                      "li",
                      { key: pc.id },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "categoryProducts",
                                params: { slug: pc.slug },
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(pc.name) +
                                "\n                  "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer-bottom" }, [
        _c("p", [_vm._v(_vm._s(_vm.settings.copyright))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }