var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    { staticClass: "btn btn-primary", attrs: { type: "submit" } },
    [
      _vm.loading
        ? _c("span", { staticClass: "spinner-border spinner-border-sm" })
        : _vm._e(),
      _vm._v("\n\t" + _vm._s(_vm.title) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }