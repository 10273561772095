var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row align-self-stretch px-2" },
    _vm._l(5, function (product) {
      return _c("div", { key: product, staticClass: "flex-item" }, [
        _vm._m(0, true),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "skeleton-card" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "img" }),
        _vm._v(" "),
        _c("div", { staticClass: "details" }, [
          _c("span", { staticClass: "name" }),
          _vm._v(" "),
          _c("span", { staticClass: "quantity" }),
          _vm._v(" "),
          _c("span", { staticClass: "price" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "btns" }, [
        _c("div", { staticClass: "btn btn-1" }),
        _vm._v(" "),
        _c("div", { staticClass: "btn btn-1 mt-2" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }