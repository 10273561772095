var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card-header" }),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-light text-dark btn-block",
          attrs: { type: "button" },
          on: { click: _vm.goHome },
        },
        [_vm._v("\n      الصفحة الرئيسية\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-success btn-block mt-2",
          attrs: { type: "button" },
          on: { click: _vm.sendToWhatsApp },
        },
        [_vm._v("\n      إرسال التفاصيل إلى واتساب\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-warning btn-block mt-2",
          attrs: { type: "button" },
          on: { click: _vm.sendOrderNumberToWhatsApp },
        },
        [_vm._v("\n      إرسال رقم الطلب إلى واتساب\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }