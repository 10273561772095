<template>
  <div> <!-- Single root element -->
    <div class="card-header">
      <!-- header content -->
    </div>
    <div class="card-body">
      <button
        type="button"
        @click="goHome"
        class="btn btn-light text-dark btn-block"
      >
        الصفحة الرئيسية
      </button>

      <button
        type="button"
        @click="sendToWhatsApp"
        class="btn btn-success btn-block mt-2"
      >
        إرسال التفاصيل إلى واتساب
      </button>

      <button
        type="button"
        @click="sendOrderNumberToWhatsApp"
        class="btn btn-warning btn-block mt-2"
      >
        إرسال رقم الطلب إلى واتساب
      </button>
    </div>
  </div>
</template>

        
        <script>
        import { mapGetters, mapActions } from "vuex";
        
        export default {
          data() {
            return {
              productImage: '/theme/img/main-banner.jpg', // Replace with the actual image path
            };
          },
          mounted() {
            // location.reload();
            // this.resetStep();
            console.log(this.orderData);
          },
           computed: {
            ...mapGetters('checkout', ['orderData']),
          },
          methods: {
            ...mapActions("checkout", ["resetStep", "previousStep", "nextStep"]),
            
            sendToWhatsApp() {
              const orderDetails = `
                تفاصيل التسليم:
                عنوان التسليم: ${this.deliveryDetails ? this.deliveryDetails.deliveryAddress.address : ''}
                نوع التوصيل: Express
                طريقة الدفع: Cash On Delivery
                المجموع الفرعي: ${this.$currency} ${this.orderData.sub_total}
                الخصم: ${this.$currency} ${this.orderData.discount}
                المجموع الإجمالي: ${this.$currency} ${this.orderData.grand_total}
              `;
        
              const phoneNumber = '201090537394'; 
              const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(orderDetails)}`;
              window.open(whatsappUrl, '_blank');
            },
            sendOrderNumberToWhatsApp() {
              const message = `
              . أود الاستفسار عن حالة الطلب.
              رقم الطلب: ${this.orderData.order_id}`;
              const phoneNumber = '201090537394'; 
              const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
              window.open(whatsappUrl, '_blank');
            },
            
            goHome() {
              this.resetStep();
              this.$router.push({ name: "home" });
            },
          },
        };
        </script>
        
        <style scoped>
        .card-img-top {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
        </style>
        