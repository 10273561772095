var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.keyword
      ? _c("h6", { staticClass: "text-center pt-3" }, [
          _vm._v("\n    " + _vm._s(_vm.$lang["Search result for"]) + " "),
          _c("b", [_vm._v(_vm._s(_vm.keyword))]),
        ])
      : _c("h6", { staticClass: "text-center pt-3" }, [
          _vm._v(_vm._s(_vm.$lang["Search Product"])),
        ]),
    _vm._v(" "),
    _c("section", { staticClass: "popular-items section" }, [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          !_vm.loading && _vm.products.length > 0
            ? _c("Products", { attrs: { products: _vm.products } })
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column align-items-center text-center",
                },
                [
                  _c("img", {
                    staticClass: "d-inline-block",
                    attrs: { src: "/theme/img/no-product-found.webp" },
                  }),
                  _vm._v(" "),
                  _c("h5", { staticClass: "py-2 m-0" }, [
                    _vm._v(_vm._s(_vm.$lang["Search No Result"])),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$lang[
                            "We are sorry. We cannot find any matches for your search term"
                          ]
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "home-search secondary-search" }, [
                    _c("input", {
                      attrs: {
                        type: "text",
                        name: "search",
                        autocomplete: "off",
                        placeholder: _vm.$lang["Search Products"],
                      },
                      on: {
                        keyup: function ($event) {
                          return _vm.searchProduct($event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      { staticClass: "search-btn", attrs: { type: "button" } },
                      [_c("i", { staticClass: "icofont-search" })]
                    ),
                  ]),
                ]
              ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }